<template>
    <div class="page page-normal">
        <notifications />
        <lego-nav-bar @link-event="linkEvent" hover-effect="basic-0"
                      :logo-text="'CyberFort'"
                      drop-down-animation="bounce" :items="menu"
        class="navbar navbar-dark navbar-federal navbar-top border-bottom" :colored="true" has-separator
                      @logo-clicked="redirect">
            <template #logo>
                <img src="../assets/images/logo.png" class="mr-2" style="width: 150px; background-color: white" alt="CyberFort">
            </template>
        </lego-nav-bar>
        <div class="page-content container-fluid">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
        <div class="bgc-primary-900 p-3">
            Powered by Xeoscript Technologies
        </div>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';

import { mapActions } from 'vuex';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    components : {
        LegoNavBar
    },
    data () {
        return {
            menu : getMenu(),
            year : new Date().getFullYear()
        };
    },
    metaInfo : {
        titleTemplate : '%s - CyberFort',
        htmlAttrs     : {
            lang : 'en',
            amp  : true
        }
    },
    methods : {
        ...mapActions(['signOut']),
        redirect () {
            this.$router.push({ path : '/' });
        },

        linkEvent (name) {
            if (name === 'logout') {
                localStorage.clear();
                this.signOut();
                axios.get(urls.auth.logout);
                this.$router.push({ path : '/login/' });
            }
        }
    },
    name : 'MainLayout'
};
</script>
