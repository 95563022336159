import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon : 'fa fa-home',
            text : 'Home',
            link : '/'
        },
        {
            icon  : 'fa fa-info',
            text  : 'About',
            link  : '/about-us/',
            items : [
                {
                    icon : 'fa fa-info',
                    text : 'About',
                    link : '/about-us/'
                },
                {
                    icon : 'fa fa-user',
                    text : 'About Profile',
                    link : '/about-us-profile/'
                }
            ]
        },
        {
            icon  : 'fa fa-graduation-cap',
            text  : 'Training',
            link  : '#',
            items : [
                {
                    icon : 'fa fa-graduation-cap',
                    text : 'Training',
                    link : '/training/'
                },
                {
                    icon : 'fa fa-users',
                    text : 'Training Enquiry',
                    link : '/training-enquiry/'
                }

            ]
        },
        {
            icon  : 'fa fa-internet-explorer',
            text  : 'Website Details',
            link  : '#',
            items : [
                {
                    icon  : 'fa fa-briefcase',
                    text  : 'Career',
                    link  : '#',
                    items : [
                        {
                            icon : 'fa fa-briefcase',
                            text : 'Career',
                            link : '/career/'
                        },
                        {
                            icon : 'fa fa-users',
                            text : 'Career Applicants',
                            link : '/career-applicants/'
                        }
                    ]
                },
                {
                    icon : 'fa fa-user-secret',
                    text : 'Partner',
                    link : '/partner/'
                },
                {
                    icon : 'fa fa-address-book-o',
                    text : 'Address',
                    link : '/address/'
                },
                {
                    icon : 'fa fa-user',
                    text : 'Management',
                    link : '/management/'
                },
                {
                    icon : 'fa fa-lightbulb-o',
                    text : 'Solutions',
                    link : '/solutions/'
                },
                {
                    icon  : 'fa fa-file-text-o',
                    text  : 'Blog',
                    items : [
                        {
                            icon : 'fa fa-file-text-o',
                            text : 'Blog',
                            link : '/blog/'
                        },
                        {
                            icon : 'fa fa-bars',
                            text : 'Blog Category',
                            link : '/blog-category/'
                        }
                    ]
                }
            ]
        },
        {
            icon : 'fa fa-registered',
            text : 'Registrations',
            link : '/report/register/list/'
        },
        {
            icon : 'fa fa-envelope',
            text : 'Contact Message',
            link : '/report/contact/list/'
        },

        {
            icon  : 'fa fa-external-link',
            text  : 'Event',
            link  : '#',
            items : [
                {
                    icon : 'fa fa-picture-o',
                    text : 'Banner',
                    link : '/event/banner/'
                },
                {
                    icon : 'fa fa-server',
                    text : 'Service',
                    link : '/event/service/'
                },
                {
                    icon : 'fa fa-comments-o',
                    text : 'Testimonial',
                    link : '/testimonial/'
                },
                {
                    icon : 'fa fa-image',
                    text : 'Gallery',
                    link : '/gallery-events/'
                },
                {
                    icon : 'fa fa-film',
                    text : 'Video Gallery',
                    link : '/video-gallery-events/'
                }
            ]
        },
        {
            icon  : 'fa fa-exchange',
            text  : 'Transactions',
            link  : '#',
            items : [
                {
                    icon : 'fa fa-spinner',
                    text : 'Pending',
                    link : '/transaction/pending/'
                },
                {
                    icon : 'fa fa-check-square-o',
                    text : 'Success',
                    link : '/transaction/success/'
                },
                {
                    icon : 'fa fa-window-close-o',
                    text : 'Failure',
                    link : '/transaction/failure/'
                }
            ]
        },
        // {
        //     text : 'Test List',
        //     link : '/test/list/'
        // },
        {
            type : 'separator'
        },
        {
            icon              : 'fa fa-user-circle-o',
            text              : user.name,
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    icon : 'fa fa-unlock-alt',
                    text : 'Change Password',
                    link : '/change-password/'
                },
                {
                    icon  : 'fa fa-sign-out',
                    text  : 'Logout',
                    event : 'logout'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.superuser === true) {
        return [
            ...menu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
